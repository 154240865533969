export const findTarget = (url) => {
   if (!url) {
       return '_blank';
   }
   if (!url.startsWith('http') || !url.includes('www.comfama.com/')) {
       return '_blank';
   }
   const internalPages = [
       '/servicio-de-empleo/',
       '/agenda/',
       '/ayuda',
       '/nutricion-saludable/',
       '/red-de-amor/',
       '/bibliotecas/',
   ];
   return internalPages.some((page) => url.includes(page))
       ? '_blank'
       : '_self';
};
