import React from 'react';
import { findTarget } from '../../../utils/findTarget';
import styles from './button2.module.scss';

interface IButton2Interface {
    label: string;
    redirect: string;
    classname?: string;
    rel?: string;
    target?: string;
    id?: string;
}

const Button2 = ({
    label,
    redirect,
    classname,
    rel,
    target,
    id,
}: IButton2Interface) => {
    return (
        <a
            href={redirect}
            className={styles.a_button2 + ` ${classname}`}
            id={id ? id : ''}
            rel={
                rel
                    ? rel
                    : findTarget(redirect) === '_blank'
                    ? 'noreferrer follow'
                    : 'referrer follow'
            }
            target={target ? target : findTarget(redirect)}
        >
            {label}
        </a>
    );
};

export default Button2;
