export const generateSlug = (text: string, separator: string = '-'): string => {
    return text
        .toString()
        .normalize('NFD')
        .replace('/n', '')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, separator)
        .replace(/-+/g, separator);
};
